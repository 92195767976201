import { useMemo } from "react";
import {
  TableColumnDef,
  TableColumnProps,
  TableProps,
  TableRowActionDef,
  TableRowActionProps,
  TableRowType
} from "./types";
import { withKitType } from "../HOC";
import { DoNotCare, IdType } from "../types";
import {
  isTablePropsWithCsvHeaderRow,
  isTablePropsWithExternalPagination,
  isTablePropsWithLocalPagination,
  tableWithCsvHeaderRow,
  tableWithPagination
} from "./HOC";
import BaseTable from "./Base";

/**
 * The Table component is a flexible and powerful way to render tabular data. It can be used in a variety of ways:
 * - sortable columns
 * - row actions
 * - pagination
 * - custom column rendering
 * - custom row rendering
 * - sticky headers
 * - sticky columns
 */
export const Table = <
  Row extends TableRowType,
  Column extends TableColumnDef<Row>,
  Action extends TableRowActionDef<Row>
>(
  props: TableProps<Row, Column, Action>
) => {
  const Table = useMemo(() => {
    let Table = BaseTable;

    // CSV Data With First Row As Columns
    if (isTablePropsWithCsvHeaderRow(props)) {
      Table = tableWithCsvHeaderRow(Table);
    }

    // Pagination
    if (
      isTablePropsWithLocalPagination(props) ||
      isTablePropsWithExternalPagination(props)
    ) {
      Table = tableWithPagination(Table);
    }

    return Table;
  }, [
    isTablePropsWithLocalPagination(props),
    isTablePropsWithExternalPagination(props)
  ]);

  return <Table {...(props as DoNotCare)} />;
};

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

Table.Column = withKitType(
  "Table.Column",
  <Row extends TableRowType, ColumnKey extends IdType = IdType>(
    props: TableColumnProps<Row, ColumnKey>
  ) => <></>
);

Table.RowAction = withKitType(
  "Table.RowAction",
  <Row extends TableRowType>(props: TableRowActionProps<Row>) => <></>
);
